<template>
    <div class="content-wrapper">
        <PageHeader screenName="Cadastrar nova" :linkItems="linkItems" />

        <ConfirmModal
            itemIdentifier="unidade"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="cancelRegister"
            @canceled="closeModalConfirm"
        />

        <SucessoModal
            :modalShow="modalSuccess.showModal"
            :typeModal="modalSuccess.typeModal"
            :caseModal="modalSuccess.caseModal"
            @confirmed="redirectToList"
        />

        <DepartamentoModal
            :modalShow="modalErroRegiao.showModal"
            :typeModal="modalErroRegiao.caseModal"
            @confirmed="redirectToDepartamentoView"
        />

        <TimedAlert variant="success" message="Unidade salva com sucesso!" :show="savedAlert" />

        <main class="card p-2">
            <section class="form">
                <validation-observer ref="UnidadesRules">
                    <b-form>
                        <b-row>
                            <b-col lg="3" md="6" sm="12">
                                <validation-provider
                                    #default="{ errors }"
                                    name="departamentoRegional"
                                    rules="required"
                                >
                                    <b-form-group label="Departamento Regional*" label-for="regionalDepartment-input"
                                        :state="errors.length > 0 || form.errors.regionalDepartment ? false:null"
                                    >
                                        <v-select
                                            id="regionalDepartment-input"
                                            v-model="form.regionalDepartment"
                                            :options="regionalDepartments"
                                            label="descricao"
                                            placeholder="Selecione um departamento"
                                            @input="handleSelectRegionalDepartment"
                                            :clearable="false"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.regionalDepartment" class="text-danger">
                                            {{ form.errors.regionalDepartment }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col lg="4" md="6" sm="12" class="pr-0">
                                <b-form-group label="Nome da Unidade Operacional*" label-for="name-input">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="name"
                                        rules="required"
                                    >
                                        <b-form-input
                                            :state="errors.length > 0 || form.errors.name ? false:null"
                                            id="name-input"
                                            v-model="form.name"
                                            placeholder="Ex: Sede DR/SC"
                                        />
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.name" class="text-danger">
                                            {{ form.errors.name }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="1" md="6" sm="12" class="pr-0">
                            </b-col>
                            <b-col lg="4" md="6" sm="12">
                                <b-form-group label="Código para integração externa" label-for="codigoIntegracao-input">
                                    <b-form-input
                                        :state=" form.errors.codeExternal ? false:null"
                                        id="codigoIntegracao-input"
                                        autocomplete="off"
                                        v-mask="('X'.repeat(7))"
                                        v-model="form.codeExternalIntegration"
                                        placeholder="Ex: XPTO-123"
                                        type="text"
                                    />
                                    <small v-if="form.errors.codeExternal" class="text-danger">
                                        {{ form.errors.codeExternal }}
                                    </small>
                                </b-form-group>
                            </b-col>
                            
                        </b-row>
                        <b-row>
                            <b-col lg="3" md="6" sm="12">
                                <validation-provider
                                    #default="{ errors }"
                                    name="cnpj"
                                    rules="required"
                                >
                                    <b-form-group label="CNPJ da Unidade Operacional*" label-for="cnpj-input">
                                        <b-form-input
                                            :state="errors.length > 0 || form.errors.cnpj ? false:null"
                                            id="cnpj-input"
                                            autocomplete="off"
                                            v-mask="('XX.XXX.XXX/XXXX-XX')"
                                            v-model="form.cnpj"
                                            placeholder="00.000.000/0000-00"
                                        />
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.cnpj" class="text-danger">
                                            {{ form.errors.cnpj }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col lg="3" md="6" sm="12" class="pr-0">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Cidade"
                                    rules="required"
                                >
                                    <b-form-group label="Cidade (local físico)*" label-for="city-input"
                                        :state="errors.length > 0 || form.errors.city ? false:null"
                                    >
                                        <v-select
                                            id="city-input"
                                            v-model="form.city"
                                            :reduce="(option => option.id_cidade)"
                                            :options="cities"
                                            label="nome"
                                            placeholder="Selecione uma cidade"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.city" class="text-danger">
                                            {{ form.errors.city }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col lg="2" md="6" sm="12" class="pr-0">
                            </b-col>
                            <b-col lg="4" md="6" sm="12">
                                <b-form-group label="Código SMD da Unidade Operacional" label-for="codigoSmd-input">
                                    <b-form-input
                                        :state=" form.errors.smdCodeUnit ? false:null"
                                        id="codigoSmd-input"
                                        autocomplete="off"
                                        v-mask="('X'.repeat(7))"
                                        v-model="form.smdCodeUnit"
                                        placeholder="Ex: XPTO-123"
                                        type="text"
                                    />
                                    <small v-if="form.errors.smdCodeUnit" class="text-danger">
                                        {{ form.errors.smdCodeUnit }}
                                    </small>
                                </b-form-group>
                            </b-col>
                            <b-col lg="2" md="6" sm="12" class="pr-0">
                            </b-col>
                
                        </b-row>
                        <b-row>
                            <b-col lg="3" md="6" sm="12" v-if="useRegion">
                                <validation-provider
                                    #default="{ errors }"
                                    name="region"
                                    rules="required"
                                >
                                    <b-form-group label="Região*" label-for="region-input"
                                        :state="errors.length > 0 || form.errors.region ? false:null"
                                    >
                                        <v-select
                                            id="region-input"
                                            v-model="form.region"
                                            :reduce="(option => option.id_regiao)"
                                            :options="region"
                                            label="descricao"
                                            placeholder="Selecione uma região"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.region" class="text-danger">
                                            {{ form.errors.region }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                        <ResponsibleField
                            labelName="Responsável por esta Unidade Operacional"
                            :error="form.errors.responsible"
                            @selected="fillResponsible"
                            :preSelected="form.responsible"
                            :idDepartamento="form.regionalDepartment ? form.regionalDepartment.id_departamento : null"
                        />
                    </b-form>
                    <LinkCitiesUnits 
                        :cities="cities"
                        :ciityPhysicalLocation="form.city"
                        @linkedCities="(cities) => citiesLinked = cities"
                        :noCityLinked="noCityLinked"
                    />
                </validation-observer>
            </section>
             <section class="buttons mt-3">
                <b-button id="save-unidade"
                    class="mr-2"
                    variant="custom-blue"
                    @click.prevent="validationForm(true)"
                    :disabled="saving || savingAndNew"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar unidade</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="save-new-unidade"
                    class="mr-2"
                    variant="custom-blue"
                    @click.prevent="validationForm(false)"
                    :disabled="saving || savingAndNew"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar e cadastrar um nova</span>
                    <span v-if="savingAndNew" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-register-departamento"
                    :disabled="saving || savingAndNew"
                    variant="outline-danger"
                    @click.prevent="openModalConfirm"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import LinkCitiesUnits from '@/views/components/custom/LinkCitiesUnits/LinkCitiesUnits.vue';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue';
import ResponsibleField from '@/views/components/custom/field-select-responsible-departamento/ResponsibleField.vue';
import handleErrorsService from './Services/handleErros';
import DepartamentoModal from '@/views/components/custom/modals/ModalErrorDepartamento.vue';
import {getUserData} from "@/auth/utils";

export default {
    title: 'Cadastrar unidade',

    components: {
        PageHeader, BForm, BFormGroup, BFormInput, BButton,
        BCol, BRow, ValidationProvider, ValidationObserver,
        vSelect, ConfirmModal, SucessoModal, TimedAlert, ResponsibleField,
        DepartamentoModal, LinkCitiesUnits
    },

    data() {
        return {
             userData: getUserData(),
             linkItems: [
                {
                    name: 'Unidades Operacionais',
                    routeName: 'unidade-list',
                },
                {
                    name: 'Cadastrar nova',
                    active: true
                }
            ],
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSuccess: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'criacao'
            },
            modalErroRegiao: {
                showModal: false,
                caseModal: 'sem-regiao-criacao-unidade'
            },
            required,
            savedAlert: false,
            saving: false,
            savingAndNew: false,
            form: {
                name: null,
                smdCodeUnit: null,
                codeExternalIntegration: null,
                cnpj: null,
                city: null,
                region: null,
                regionalDepartment: null,
                responsible: null,
                email: null,
                errors: {
                    name: null,
                    cnpj: null,
                    smdCodeUnit:null,
                    codeExternalIntegration: null,
                    city: null,
                    regionalDepartment: null,
                    region: null,
                    responsible: null
                }
            },
            useRegion: false,
            region: [],
            cities: [],
            regionalDepartments: [],
            citiesLinked: [],
            redirectToListSelected: false,
            noCityLinked: false
        }
    },

    mounted () {
        this.loadRegionalDepartments();
    },

    methods: {
        validationForm(redirectToList) {
            this.noCityLinked = false;
            this.$refs.UnidadesRules.validate().then(success => {
                if (success) {
                    redirectToList ? this.saving = true : this.savingAndNew = true;

                    this.redirectToListSelected = redirectToList;

                    if(this.citiesLinked.length == 0) {
                        this.noCityLinked = true;
                        this.saving = false
                        this.savingAndNew = false;

                        return;
                    }

                    this.saveUnidade();
                }
            })
        },

        saveUnidade() {
            const parameters = {
                'descricao': this.form.name,
                'id_regiao': this.form.region,
                'id_cidade': this.form.city,
                'id_departamento': this.form.regionalDepartment.id_departamento,
                'codigo_smd_integracao': this.form.codeExternalIntegration,
                'codigo_smd' : this.form.smdCodeUnit,
                'cnpj': this.form.cnpj.replaceAll(/[^0-9]/g, ''),
                'id_usuario_responsavel': this.form.responsible,
                'cidades_vinculadas': this.citiesLinked.map(city => city.id_cidade)
            }

            this.$http.post(this.$api.unidade(), parameters).then(() => {
                this.saving = false;
                this.savingAndNew = false;
                this.cleanForm();

                if(this.redirectToListSelected) {
                    this.openSuccessModal();
                    return;
                }

                this.savedAlert = !this.savedAlert;
            }).catch(({ response: { data, status } }) => {
                if(status == 409){
                    this.modalErroRegiao.showModal = true
                }else{
                    handleErrorsService.handleErrosUnidade(data, this.form.errors);
                }

                this.saving = false;
                this.savingAndNew = false;
            })
        },

        cleanForm() {
            this.form.name = null;
            this.form.smdCodeUnit = null;
            this.form.codeExternalIntegration = null;
            this.form.cnpj = null;
            this.form.city = null;
            this.form.region = null;
            this.form.responsible = null;

            this.form.errors.name = null;
            this.form.errors.smdCodeUnit = null;
            this.form.errors.codeExternalIntegration = null;
            this.form.errors.cnpj = null;
            this.form.errors.city = null;
            this.form.errors.region = null;
            this.form.errors.responsible = null;

            this.$refs.UnidadesRules.reset();
        },

        redirectToList() {
            this.$router.push({ name: 'unidade-list' });
        },

        loadRegion() {
            this.$http.get(this.$api.regiao(), {
                params: {
                    ativo: true,
                    id_departamento:this.form.regionalDepartment.id_departamento
                }
            }).then(({ data }) => {
               this.region = data;
            });
        },

        loadCidades() {
            this.$http.get(this.$api.getCidadesDoEstado(this.form.regionalDepartment.uf)).then(({ data }) => {
                this.cities = data.cidades;
            });
        },

        loadRegionalDepartments() {
            this.$http.get(this.$api.departamento(), {
                params: {
                    ativo: true
                }
            }).then(({data}) => {
                this.regionalDepartments = data;
            });
        },

        handleSelectRegionalDepartment(){
      
            if(this.form.regionalDepartment.divisao_por_regiao){
                this.useRegion = true;
                this.loadRegion();
            }else{
                this.useRegion = false;
            }
            this.form.city = null;
            this.form.region = null;
            this.form.responsible = null;
            this.loadCidades();
        },

        cancelRegister() {
            this.$router.push({ name: 'unidade-list' });
        },

        openModalConfirm() {
            this.modal.showModal = true;
        },

        openSuccessModal() {
            this.modalSuccess.showModal = true;
        },

        fillResponsible(event) {
            this.form.responsible = event;
        },

        closeModalConfirm() {
            this.modal.showModal = false;
        },

        redirectToDepartamentoView() {
            this.$router.push({ name: 'departamento-view' });
            this.modalErroRegiao.showModal = false;
        }
    },
}
</script>


